import { authHeader, handleResponse } from '@/helpers/fakebackend/auth-header';

export const clientService = {
    registerClient,
    registerClientSupport,
    listClient,
    listClientSupport,
    detailClient,
    detailClientSupport,
    deleteClient,
    deleteClientSupport,
    updateClient,
    updateClientSupport
};

function registerClient(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients`, requestOptions).then(handleResponse);
}
function registerClientSupport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges`, requestOptions).then(handleResponse);
}

function detailClient(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + data, requestOptions).then(handleResponse);
}
function detailClientSupport(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function listClient(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients?` + query, requestOptions).then(handleResponse);
}

function listClientSupport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + id + `/person-in-charges`, requestOptions).then(handleResponse);
}

function deleteClient(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + data.id, requestOptions).then(handleResponse);
}

function deleteClientSupport(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function updateClient(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + data.id, requestOptions).then(handleResponse);
}

function updateClientSupport(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}
