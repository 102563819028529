import { authHeader, handleResponse } from '@/helpers/fakebackend/auth-header';

export const faqService = {
    registerFaq,
    registerAnswer,
    listFaqs,
    detailFaq,
    deleteAnswer,
    deleteFaq,
    updateFaqs,
    updateFaqsAnswerDefault,
    updateAnswer
};

function registerFaq(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs`, requestOptions).then(handleResponse);
}

function registerAnswer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers`, requestOptions).then(handleResponse);
}

function detailFaq(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/` + data, requestOptions).then(handleResponse);
}

function listFaqs(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs?` + query, requestOptions).then(handleResponse);
}

function deleteAnswer(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers/` + data.id, requestOptions).then(handleResponse);
}

function deleteFaq(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/` + data.id, requestOptions).then(handleResponse);
}

function updateFaqs(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/` + data.id, requestOptions).then(handleResponse);
}
function updateFaqsAnswerDefault(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/${data.id}/answer-default/`, requestOptions).then(handleResponse);
}

function updateAnswer(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers/${data.id}`, requestOptions).then(handleResponse);
}
