import { userService } from '../../helpers/fakebackend/user.service';
import router from '../../router/index';

const user = JSON.parse(localStorage.getItem('user'));
export const state = user ? { status: { loggeduser: true }, user } : { status: {}, user: null };

export const actions = {
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });
        return userService.login(email, password).then(
            (user) => {
                if (user.data) {
                    window.$cookies.set('token', user.data.access_token);
                    commit('loginSuccess', user.data.user);
                }

                return user;
            },
            (error) => {
                commit('loginFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    forgot({ dispatch, commit }, email) {
        commit('loginRequest', email);
        return userService.forgot(email).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('loginFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    resetPass({ dispatch, commit }, params) {
        const { password, password_confirm, token } = params;
        commit('loginRequest', params);
        return userService.reset(password, password_confirm, token).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('loginFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    logout({ commit }) {
        userService.logout();
        commit('logout');
    },

    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user).then(
            (user) => {
                commit('registerSuccess', user);
                dispatch('notification/success', 'Registration successful', { root: true });
                router.push('/login');
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    profile({ dispatch, commit }) {
        commit('refreshRequest');
        userService.profile().then(
            (user) => {
                commit('loginSuccess', user);

                if (user?.role) {
                    if (user.role.name == 'part_time') {
                        document.getElementById('page-topbar').style.backgroundColor = '#fbbc04';
                    }
                    if (user.role.name == 'administrator' || user.role.name == 'sale' || user.role.name == 'instructor') {
                        document.getElementById('page-topbar').style.backgroundColor = '#ea4335';
                    }
                    if (user.role.name == 'partner') {
                        document.getElementById('page-topbar').style.backgroundColor = '#4285f4';
                    }
                }
            },
            (error) => {
                commit('refreshFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    refreshRequest(state) {
        state.status = { refresh: true };
    },
    refreshSuccess(state) {
        state.status = {};
    },
    refreshFailure(state) {
        state.status = {};
    }
};
