import store from '@/state/store';

// import { routerCampaign } from './modules/campaign';
// import { routerClient } from './modules/client';
// import { routerDocuments } from './modules/documents';
// import { routerEmployee } from './modules/employee';
// import { routerFaq } from './modules/faq';
// import { routerHistory } from './modules/history';
// import { routerMaster } from './modules/master';
// import { routerPartner } from './modules/partner';
// import { routerPrize } from './modules/prize';
// import { routerRequire } from './modules/require';
// import { routerRoles } from './modules/roles';
// import { routerSettingPhone } from './modules/setting-phone';

import SubdomainPage from '@/views/SubdomainPage.vue';

const hostname = window.location.hostname;
console.log('hostname', hostname);

const domainParts = hostname.split('.');
const isLocalhost = hostname === 'localhost';
const subdomain = isLocalhost ? 'quang-cao' : domainParts[0];

const routes =
    subdomain[
        {
            path: '/',
            name: 'SubdomainPage',
            component: SubdomainPage,
            meta: { subdomain }
        }
    ];
// : [
//       {
//           path: '/login',
//           name: 'login',
//           component: () => import('../views/pages/account/login'),
//           meta: {
//               beforeResolve(routeTo, routeFrom, next) {
//                   // If the user is already logged in
//                   if (store.getters['auth/loggedIn']) {
//                       // Redirect to the home page instead
//                       next({ name: 'home' });
//                   } else {
//                       // Continue to the login page
//                       next();
//                   }
//               }
//           }
//       },
//       {
//           path: '/forgot-password',
//           name: 'forgotPassword',
//           component: () => import('../views/pages/account/forgot-pass'),
//           meta: {
//               beforeResolve(routeTo, routeFrom, next) {
//                   // If the user is already logged in
//                   if (store.getters['auth/loggedIn']) {
//                       // Redirect to the home page instead
//                       next({ name: 'home' });
//                   } else {
//                       // Continue to the login page
//                       next();
//                   }
//               }
//           }
//       },
//       {
//           path: '/reset-password',
//           name: 'resetPassword',
//           component: () => import('../views/pages/account/reset-pass'),
//           meta: {
//               beforeResolve(routeTo, routeFrom, next) {
//                   // If the user is already logged in
//                   if (store.getters['auth/loggedIn']) {
//                       // Redirect to the home page instead
//                       next({ name: 'home' });
//                   } else {
//                       // Continue to the login page
//                       next();
//                   }
//               }
//           }
//       },
//       {
//           path: '/logout',
//           name: 'logout',
//           meta: {
//               authRequired: true,
//               beforeResolve(routeTo, routeFrom, next) {
//                   if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
//                       store.dispatch('auth/logOut');
//                   } else {
//                       store.dispatch('authfack/logout');
//                   }
//                   const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.push('/login'));
//                   // Navigate back to previous page, or home as a fallback
//                   next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom });
//               }
//           }
//       },
//       {
//           path: '/',
//           name: 'home',
//           meta: {
//               authRequired: true,
//               role: [],
//               roleDefault: 'all',
//               typeRouterForm: false,
//               titleJP: 'ダッシュボード',
//               pageNames: [
//                   {
//                       text: 'ダッシュボード',
//                       active: true
//                   }
//               ]
//           },
//           component: () => import('../views/pages/dashboard/index')
//       },
//       ...routerCampaign,
//       ...routerClient,
//       ...routerDocuments,
//       ...routerEmployee,
//       ...routerFaq,
//       ...routerHistory,
//       ...routerMaster,
//       ...routerPartner,
//       ...routerPrize,
//       ...routerRequire,
//       ...routerRoles,
//       ...routerSettingPhone,
//       {
//           path: '/error-page',
//           name: 'errorPage',
//           meta: { authRequired: true, roleDefault: 'all', role: [] },
//           component: () => import('../views/pages/error-page/main.vue')
//       }
//   ];

// Vue.prototype.$subdomain = subdomain;

// Sử dụng trong component:
console.log(subdomain);

export default routes;
