import Vue from 'vue';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueSweetalert2 from 'vue-sweetalert2';

import router from './router';
import store from '@/state/store';
import i18n from './i18n';

import '@/assets/scss/app.scss';

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(require('vue-cookies'));

let vue = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
window.$cookies = vue.$cookies;
