import { authHeader, handleResponse } from '@/helpers/fakebackend/auth-header';

export const prizeService = {
    registerPrize,
    registerAnswer,
    listPrize,
    detailPrize,
    deleteAnswer,
    deletePrize,
    updateFaqs,
    updateFaqsAnswerDefault,
    updateAnswer,
    updatePrize,
    registerPrizeReturn,
    deletePrizeReturn,
    updatePrizeReturn,
    listPrizeReturn,
    getPrizesAll
};

function registerPrize(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes`, requestOptions).then(handleResponse);
}
function registerPrizeReturn(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes`, requestOptions).then(handleResponse);
}

function listPrizeReturn(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes?` + query, requestOptions).then(handleResponse);
}
function registerAnswer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers`, requestOptions).then(handleResponse);
}

function detailPrize(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/` + data, requestOptions).then(handleResponse);
}

function listPrize(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes?` + query, requestOptions).then(handleResponse);
}

function deleteAnswer(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers/` + data.id, requestOptions).then(handleResponse);
}

function deletePrize(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/` + data.id, requestOptions).then(handleResponse);
}

function deletePrizeReturn(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes/` + data.id, requestOptions).then(handleResponse);
}

function updateFaqs(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/` + data.id, requestOptions).then(handleResponse);
}
function updatePrizeReturn(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes/` + data.id, requestOptions).then(handleResponse);
}
function updateFaqsAnswerDefault(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/${data.id}/answer-default/`, requestOptions).then(handleResponse);
}

function updateAnswer(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers/${data.id}`, requestOptions).then(handleResponse);
}

function updatePrize(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/${data.id}`, requestOptions).then(handleResponse);
}

function getPrizesAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/all`, requestOptions).then(handleResponse);
}
