import { authHeader, handleResponse } from '@/helpers/fakebackend/auth-header';

export const manualService = {
    detailManual,
    updateManual
};

function detailManual(code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/information-manual-file/${code}`, requestOptions).then(handleResponse);
}

function updateManual(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/information-manual-file/${data.code}`, requestOptions).then(handleResponse);
}
