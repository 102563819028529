import { campaignService } from '@/helpers/fakebackend/campaign.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    registerCampaign({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return campaignService.registerCampaign(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    copyCampaign({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return campaignService.copyCampaign(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateCampaign({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return campaignService.updateCampaign(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listCampaign({ dispatch, commit }, query) {
        commit('listRequest', []);
        return campaignService.listCampaign(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    getDataDocuments({ dispatch, commit }, query) {
        commit('listRequest', []);
        return campaignService.getDataDocuments(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailCampaign({ dispatch, commit }, data) {
        commit('listRequest', []);
        return campaignService.detailCampaign(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.deleteCampaign(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    lockCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.lock(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    unLockCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.unlock(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    approveCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.approve(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    addBookmark({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.addBookmark(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getBookmark({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.getBookmark(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteBookmark({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.deleteBookmark(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    triggerDocuments({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.triggerDocuments(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDocuments({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.getDocuments(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    postDocuments({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.postDocuments(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    historyDocuments({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.historyDocuments(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    uploadFile({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.uploadFile(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    getRuleSavePayLoad({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.getRuleSavePayLoad(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    postRuleSavePayLoad({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.postRuleSavePayLoad(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    setBookmarkCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.setBookmarkCampaign(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    unsetBookmarkCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return campaignService.unsetBookmarkCampaign(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
