import { manualService } from '@/helpers/fakebackend/manual.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    updateManual({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return manualService.updateManual(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailManual({ dispatch, commit }, data) {
        commit('listRequest', []);
        return manualService.detailManual(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
