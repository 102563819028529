import { prizeService } from '@/helpers/fakebackend/prize.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    registerPrize({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return prizeService.registerPrize(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateFaqs({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return prizeService.updateFaqs(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateFaqsAnswerDefault({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return prizeService.updateFaqsAnswerDefault(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerAnswer({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return prizeService.registerAnswer(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateAnswer({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return prizeService.updateAnswer(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listPrize({ dispatch, commit }, query) {
        commit('listRequest', []);
        return prizeService.listPrize(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updatePrize({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return prizeService.updatePrize(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailPrize({ dispatch, commit }, data) {
        commit('listRequest', []);
        return prizeService.detailPrize(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteAnswer({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return prizeService.deleteAnswer(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deletePrize({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return prizeService.deletePrize(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deletePrizeReturn({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return prizeService.deletePrizeReturn(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerPrizeReturn({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return prizeService.registerPrizeReturn(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updatePrizeReturn({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return prizeService.updatePrizeReturn(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listPrizeReturn({ dispatch, commit }, query) {
        commit('listRequest', []);
        return prizeService.listPrizeReturn(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getPrizesAll({ dispatch, commit }) {
        commit('listRequest', []);
        return prizeService.getPrizesAll().then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
