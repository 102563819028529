<template>
    <div>
        <h1>SUbdomain</h1>
    </div>
</template>

<script>
export default {
    name: 'SubdomainPage',
    mounted() {
        console.log('this>>>>', this.$route);
    }
};
</script>

<style lang="scss" scoped></style>
