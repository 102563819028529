import { dashboardService } from '@/helpers/fakebackend/dashboard.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    listCountEmployeeDashboard({ dispatch, commit }, query) {
        commit('listRequest', []);
        return dashboardService.GET_COUNT_EMPLOYEE_DASHBOARD(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
