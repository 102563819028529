<template>
    <div>
        <div id="app">
            <router-view />
            <button v-if="isActive && isVisible" type="button" class="btn rounded-circle back__to--top" id="btn-back-to-top" @click="backToTop">
                <i class="fas fa-arrow-up"></i>
            </button>
        </div>
    </div>
</template>

<script>
import appConfig from '@/app.config';
import 'vue2-datepicker/locale/ja';

export default {
    name: 'app',
    page: {
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        }
    },
    data() {
        return {
            isVisible: false
        };
    },
    methods: {
        backToTop() {
            this.smoothScrollTo(0);
        },

        smoothScrollTo(targetY) {
            const startY = window.scrollY;
            const distance = targetY - startY;
            const duration = 500; // Thời gian cuộn (ms)
            let startTime = null;

            function scrollAnimation(currentTime) {
                if (!startTime) startTime = currentTime;
                const timeElapsed = currentTime - startTime;
                const progress = Math.min(timeElapsed / duration, 1);
                const ease = progress * (2 - progress); // Easing function
                window.scrollTo(0, startY + distance * ease);

                if (progress < 1) {
                    requestAnimationFrame(scrollAnimation);
                }
            }

            requestAnimationFrame(scrollAnimation);
        },

        handleScroll() {
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                this.isVisible = true;
            } else {
                this.isVisible = false;
            }
        }
    },
    computed: {
        isActive() {
            const publicPages = ['/login', '/register', '/forgot-password'];
            return !publicPages.includes(this.$route.path);
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>

<style lang="scss"></style>
